/**
 * Include svg icon as a font and characters
 *
 * @warning this is generated file, do not edit
 */
@mixin icon($name, $selector: "&::before", $font-family: iconfont) {
	$icon: map-get($iconfont-icons, $name);

	@if map-has-key($iconfont-icons, $name) {
		$name: unquote("\"\\#{map-get($iconfont-icons, $name)}\"");
	}

	@if $font-family == iconfont {

		@include __iconfont-face();
	}

	#{$selector} {
		content: $name;
		font-family: $font-family;
		font-style: normal;
		font-weight: 400;
		vertical-align: top;
		@content;
	}
}

/**
 * Include font face for icons only when needed
 */
@mixin __iconfont-face() {

	@if not $__iconfont-face {

		@at-root {

			@font-face {
				font-family: iconfont;
				src:
					url(../font/iconfont.eot#iefix) format("embedded-opentype"),
					url(../font/iconfont.woff2) format("woff2"),
					url(../font/iconfont.woff) format("woff"),
					url(../font/iconfont.ttf) format("truetype"),
					url(../font/iconfont.svg#iconfont) format("svg");
			}
		}
		$__iconfont-face: true !global;
	}
}

$__iconfont-face: false;

/**
 * Define iconfont map
 */
$iconfont-icons: (
	bluesky: "f101",
	capture: "f102",
	close: "f103",
	down: "f104",
	email: "f105",
	facebook: "f106",
	gdpr: "f107",
	instagram: "f108",
	left: "f109",
	linkedin: "f10a",
	menu: "f10b",
	open-access: "f10c",
	pin: "f10d",
	quote-close: "f10e",
	quote-open: "f10f",
	reporting: "f110",
	right: "f111",
	search: "f112",
	twitter: "f113",
	up: "f114",
	youtube: "f115",
);

/**
 * Add icon classes
 */
$iconfont-generate-classes: false !default;

@if $iconfont-generate-classes {

	.icon-bluesky::before {

		@include icon(bluesky);
	}

	.icon-capture::before {

		@include icon(capture);
	}

	.icon-close::before {

		@include icon(close);
	}

	.icon-down::before {

		@include icon(down);
	}

	.icon-email::before {

		@include icon(email);
	}

	.icon-facebook::before {

		@include icon(facebook);
	}

	.icon-gdpr::before {

		@include icon(gdpr);
	}

	.icon-instagram::before {

		@include icon(instagram);
	}

	.icon-left::before {

		@include icon(left);
	}

	.icon-linkedin::before {

		@include icon(linkedin);
	}

	.icon-menu::before {

		@include icon(menu);
	}

	.icon-open-access::before {

		@include icon(open-access);
	}

	.icon-pin::before {

		@include icon(pin);
	}

	.icon-quote-close::before {

		@include icon(quote-close);
	}

	.icon-quote-open::before {

		@include icon(quote-open);
	}

	.icon-reporting::before {

		@include icon(reporting);
	}

	.icon-right::before {

		@include icon(right);
	}

	.icon-search::before {

		@include icon(search);
	}

	.icon-twitter::before {

		@include icon(twitter);
	}

	.icon-up::before {

		@include icon(up);
	}

	.icon-youtube::before {

		@include icon(youtube);
	}
}
