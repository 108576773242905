/**
 * Generate complex media queries by adding only keywords or numbers
 *
 * @example
 * 	   @include media(1024px)
 *         // @media (min-width: 1024px)
 *     @include media(screen 1024px)
 *         // @media only screen and (min-width: 1024px)
 *     @include media(max 1024px)
*          // @media (max-width: 1024px)
 *     @include media(height 300px)
 *         // @media (min-height: 300px)
 *     @include media(max-height 300px)
 *         // @media (max-height: 300px)
 *     @include media(screen (max 1024px))
 *         // @media only screen and (max-width: 1024px)
 *     @include media(screen 620px (max 1024px))
 *         // @media only screen and (min-width: 620px) and (max-width: 1024px)
 *     @include media(620px, max 1024px)
 *         // @media (min-width: 620px), (max-width: 1024px)
 */
@mixin media($query...) {
	$media-query: media-parse-lists($query);

	@media #{$media-query} {
		@content;
	}
}

/**
 * Join multi media queries
 */
@function media-parse-lists($queries) {
	$media: null;

	@each $query in $queries {

		$map: media-parse-list($query);

		@if $map {
			$parsed: null;

			@each $prop, $value in $map {
				$part: "";

				@if $value {

					@if $prop == type {
						$part: #{"only " + $value};
					} @else {
						$part: #{"(" + $prop + ": " + $value + ")"};
					}

					@if $parsed {
						$parsed: unquote("#{$parsed} and #{$part}");
					} @else {
						$parsed: $part;
					}
				}
			}

			@if $media {
				$media: unquote("#{$media}, #{$parsed}");
			} @else {
				$media: $parsed;
			}
		}
	}

	@if $media {

		@return $media;
	}

	@return $queries;
}

/**
 * Get a singular media query
 */
@function media-parse-list($query) {
	$prop: media-parse-list-prop($query);

	@if $prop {

		@return media-parse-part($query);
	}

	@return media-parse-items($query);
}

/**
 * Get the items of a media query
 */
@function media-parse-items($query) {
	$map: null;

	@each $part in $query {
		$part: media-parse-part($part);

		@if $part {

			@if $map {
				$map: map-merge($map, $part);
			} @else {
				$map: $part;
			}
		}
	}

	@return $map;
}

/**
 * Get the parts of a media query item
 */
@function media-parse-part($part) {

	@if global-variable-exists(media-queries) and map-has-key($media-queries, $part) {

		@return media-parse-list(map-get($media-queries, $part));
	}

	@if index($__media-types, $part) != null {

		@return (type: $part);
	}

	@if type-of($part) == number {

		@return (min-width: $part);
	}

	@if type-of($part) == list {
		$prop: media-parse-list-prop($part);
		$value: media-parse-list-value($part);

		@if $prop and $value {

			@return ($prop: $value);
		}
	}

	@return null;
}

/**
 * Get a property segment of a list in a media query part
 */
@function media-parse-list-prop($list) {

	@each $item in $list {

		@if $item == max or $item == min {

			@return #{$item + "-width"};
		} @else if $item == width or $item == height {

			@return #{"min-" + $item};
		} @else if map-has-key($__media_properties-map, $item) {

			@return $item;
		}
	}

	@return null;
}

/**
 * Get a value segment of a list in a media query part
 */
@function media-parse-list-value($list) {
	$prop: media-parse-list-prop($list);

	@each $item in $list {

		@if index(min max width height $prop, $item) == null {

			@return $item;
		}
	}

	@return null;
}

/**
 * Default media map
 */
$__media_properties-map: (
	type: null,
	any-hover: null,
	any-pointer: null,
	min-aspect-ratio: null,
	aspect-ratio: null,
	max-aspect-ratio: null,
	min-color: null,
	color: null,
	max-color: null,
	color-gamut: null,
	min-color-index: null,
	color-index: null,
	max-color-index: null,
	grid: null,
	min-height: null,
	height: null,
	max-height: null,
	hover: null,
	inverted-colors: null,
	light-level: null,
	min-monochrome: null,
	monochrome: null,
	max-monochrome: null,
	orientation: null,
	overflow-block: null,
	overflow-inline: null,
	pointer: null,
	min-resolution: null,
	resolution: null,
	max-resolution: null,
	scan: null,
	scripting: null,
	update: null,
	min-width: null,
	width: null,
	max-width: null
);

/**
 * Media types
 */
$__media-types: (
	all,
	print,
	screen,
	speech
);
