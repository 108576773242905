/**
 * Hide an element visually without display none
 */
@mixin hide-visually() {
	position: absolute;
	left: -999999px;
	overflow: hidden;
	opacity: 0.00001;
}

/**
 * Unhide a previously visually hidden element
 */
@mixin unhide-visually($position: static, $overflow: visible) {
	position: $position;
	overflow: $overflow;
	left: auto;
	opacity: 1;
}

/**
 * Hide text but keep pseudo elements
 */
@mixin hide-text-except-pseudo(
	$font-size: 1rem,
	$color: null,
	$line-height: null,
	$font-family: null,
	$text-align: null
) {
	font-size: 0;

	&::before,
	&::after {
		color: $color;
		font-size: $font-size;
		font-family: $font-family;
		line-height: $line-height;
		text-align: $text-align;
	}
}
