/**
 * Slick slider
 */

@import "utility";
@import "stylr/_variables";
@import "variables";
@import "iconfont";
@import "stylr/load-mixins";


.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;

	&::before,
	&::after {
		content: "";
		display: table;
	}

	&::after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;

	[dir="rtl"] & {
		float: right;
	}

	img {
		display: block;
	}

	&.slick-loading img {
		display: none;
	}

	display: none;

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow.slick-hidden {
	display: none;
}

.slick-arrow {
	position: absolute;
	top: 50%;
	width: 2.5rem;
	height: 2.5rem;
	line-height: 2.4rem;
	z-index: 1000;
	transform: translateY(-50%);
	background: transparent;
	border: 1px solid c(accent-dark);
	border-radius: 100%;

	@include hide-text-except-pseudo(1.3rem);

	&:hover,
	&:focus {
		background: transparent;
		border-color: c(accent);
	}
}

.slick-prev {
	left: 0.5rem;

	@include icon(left);

	@include media(open) {
		left: 1rem;
	}
}

.slick-next {
	right: 0.5rem;

	@include icon(right);

	@include media(open) {
		right: 1rem;
	}
}


.block.testimonial-slider.is-full-width {

	@include media(unwrapped) {

		.slick-prev {
			left: 50%;
			margin-left: -600px;
		}

		.slick-next {
			right: 50%;
			margin-right: -600px;
		}
	}
}

.block.slider .overlay {
	background: transparent;
}

.multi-slick {

	.slick-slide {
		min-height: 400px;
	}

	.slick-slide > div {
		display: flex;
		min-height: 400px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.slick-arrow {
		color: c(base);
	}
}

.block.slider.logo-slider {

	.slick-slide,
	.slick-slide > div {
		min-height: 200px;
	}

	.slick-list {
		margin: 0 4rem;
	}
}
